import React from "react";
import styled from "styled-components";

const Root = styled.div`
  min-height: calc(100vh - 300px);
`;

const FullPageLayout = ({ children }) => (
  <Root>
    {children}
  </Root>
);

export default FullPageLayout;
