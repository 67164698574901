import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import { Popover } from 'src/components/Common';
import FloatContactUsForm from './FloatContactUsForm';
import { only } from 'styled-breakpoints';
import ContactSoldier from '/src/assets/soldiers/contact_soldier.svg';
import { fadeIn } from '../../style/animations';

const FloatButtonContent = styled.div`
  transition: all .2s;
`;

const FloatButton = styled.div`
  position: fixed;
  z-index: 1301;
  bottom: 20px;
  right: 40px;
  cursor: pointer;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .2s;
  ${fadeIn}

  svg {
    width: 80px;
    height: 80px;
  }

  &:hover {
    ${FloatButtonContent} {
      transform: scale(1.1);
    }
  }

  ${only('mini')} {
    right: 15px;
    bottom: 15px;

    svg {
      height: 60px;
      width: 60px;
    }
  };
`;

const FloatContactUs = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showButton, setShowButton] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    setTimeout(() => {
      setShowButton(true);
    }, 3500)
  }, []);

  const handleClick = (event) => {
    if (open) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const id = open ? 'float-contact-us-popover' : undefined;

  if (!showButton) return null;

  return (
    <>
      <FloatButton
        id="FloatContactUsButton1"
        onClick={handleClick}
        open={open}
      >
        <FloatButtonContent id="FloatContactUsButton2">
          <ContactSoldier id="FloatContactUsButton3" />
        </FloatButtonContent>
      </FloatButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <FloatContactUsForm onClose={handleClick} />
      </Popover>
    </>
  );
};

export default FloatContactUs;
