import React from "react";
import { styled } from '@mui/material/styles';
import MuiMenu from '@mui/material/Menu';

const MuiMenuStyled = styled(MuiMenu)`
  .MuiMenu-paper	{
    border-radius: 15px;
  }
`;

const Menu = ({ anchorEl, open, onClose, onMouseMove, children }) => {
  return (
    <MuiMenuStyled
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      onMouseMove={onMouseMove}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      {children}
    </MuiMenuStyled>
  );
};

export default Menu;