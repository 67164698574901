import React from "react";
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { COLORS } from 'src/style/colors';

const InputStyled = styled(InputBase)`
  color: black;
  padding: 8px 20px;
  border-radius: 10px;

  &.Mui-focused {
    outline: ${props => `2px solid ${props.outlineColor}`};
  }
`;

const Textarea = ({ className, placeholder, value, onChange, name, size }) => {
  return (
    <InputStyled
      className={className}
      placeholder={placeholder}
      multiline
      outlineColor={COLORS.PRIMARY}
      value={value}
      onChange={onChange}
      name={name}
      size={size}
    />
  )
}

export default Textarea;
