import React from "react";
import { ThemeProvider } from '@mui/material/styles';
import { ThemeProvider as SCThemeProvider } from "styled-components";
import { theme, breakpointsTheme } from '../style/theme/theme';

const RootLayout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <SCThemeProvider theme={breakpointsTheme}>
        {children}
      </SCThemeProvider>
    </ThemeProvider>
  );
};

export const BrowserRootLayout = RootLayout;
export const SsrRootLayout = RootLayout;