import React from "react";
import styled, { css } from "styled-components";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel as ResponsiveCarousel } from 'react-responsive-carousel';
import { COLORS } from 'src/style/colors';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import useMediaQuery from 'src/style/useMediaQuery';
import { only } from 'styled-breakpoints';

const Root = styled.div`
  width: 100%;
`;

const CarouselWrapper = styled.div`
  margin: 50px 0 0 0;


  .control-dots {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }

  .carousel-status {
    display: none;
  }

  ${props => props.withScale && css`
    .slide {
      transform: scale(0.9);
    
      &.selected {
        transform: scale(1);
      }
    }
  `};
`;

const Dot = styled.span`
  width: 12px;
  height: 12px;
  border: 1px solid #707070;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 50%;
  background-color: ${props => props.isSelected && COLORS.PRIMARY};
  cursor: pointer;
`;

const CarouselSide = css`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 10000;
  height: 100%;
  display: flex;
  align-items: center;
  width: 9%;

  ${only('mini')} {
    width: 15%;
    background-image: none !important;
  };
`;

const Arrow = styled.div`
  border-radius: 50%;
  display: flex;
  background: red;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all .2s;
  width: 50px;
  height: 50px;

  ${props => props.withArrowShadow && css`
    box-shadow: 0 10px 16px rgba(0, 0, 0, 0.3);
  `};

  ${props => props.arrowColor === 'primary' && css`
    background: var(--primary);
  `};

  ${props => props.arrowColor === 'sceondary' && css`
    background: var(--white);
    border: 1px solid var(--primary);

    svg {
      fill: var(--primary);
    }
  `};

  &:hover {
    width: 52px;
    height: 52px;
  }

  ${only('mobile')} {
    width: 45px;
    height: 45px;

    &:hover {
      width: 47px;
      height: 47px;
    }
  };

  ${only('mini')} {
    display: none;
    width: 35px;
    height: 27px;

    &:hover {
      width: 37px;
      height: 29px;
    }
  };
`;

const ArrowLeft = styled(Arrow)`
  margin-left: 30px;
`;

const ArrowRight = styled(Arrow)`
  margin-right: 30px;
`;

const CarouselSideLeft = styled.div`
  ${CarouselSide}
  left: 0;
  height: 100%;
  display: flex;
  align-items: center;
  ${props => props.withSideShadow && css`
    background-image: linear-gradient(to right, white, rgba(255, 255, 255, 0));
  `};
`;

const CarouselSideRight = styled.div`
  ${CarouselSide}
  right: 0;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  ${props => props.withSideShadow && css`
    background-image: linear-gradient(to left, white, rgba(255, 255, 255, 0));
  `};
`;

const Carousel = ({
  children, renderCustomIndicator, centerSlidePercentage, withArrowShadow, withScale, withSideShadow,
  arrowColor, showIndicators,
}) => {
  const mediaQuery = useMediaQuery();

  const renderArrowPrev = (onClickHandler) => (
    <CarouselSideLeft onClick={onClickHandler} withSideShadow={withSideShadow} id="carousel-side">
      <ArrowLeft withArrowShadow={withArrowShadow} arrowColor={arrowColor}>
        <ChevronLeftIcon color="info" sx={{ fontSize: mediaQuery.isMini ? 30 : 34 }} />
      </ArrowLeft>
    </CarouselSideLeft>
  );

  const renderArrowNext = (onClickHandler) => (
    <CarouselSideRight onClick={onClickHandler} withSideShadow={withSideShadow} id="carousel-side">
      <ArrowRight withArrowShadow={withArrowShadow} arrowColor={arrowColor}>
        <ChevronRightIcon color="info" sx={{ fontSize: mediaQuery.isMini ? 30 : 34 }} />
      </ArrowRight>
    </CarouselSideRight>
  );

  const renderIndicator = (onClickHandler, isSelected, index, label) => (
    <Dot onClick={onClickHandler} isSelected={isSelected} />
  );

  return (
    <Root>
      <CarouselWrapper dir="ltr" withScale={withScale}>
        <ResponsiveCarousel
          centerMode
          infiniteLoop
          centerSlidePercentage={centerSlidePercentage}
          renderArrowPrev={renderArrowPrev}
          renderArrowNext={renderArrowNext}
          renderIndicator={renderCustomIndicator || renderIndicator}
          showIndicators={showIndicators}
        >
          {children}
        </ResponsiveCarousel>
      </CarouselWrapper>
    </Root>
  )
};

export default Carousel;
