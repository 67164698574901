export const breadcrumbsSchema = (homePage, itemListElement = []) => ({
  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [{
    "@type": "ListItem",
    "position": 1,
    "name": homePage.name,
    "item": homePage.url
  },
  ...itemListElement
  ]
});