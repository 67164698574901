import React from 'react';

const ExternalLink = ({ href, children, onClick, className }) => {
  return (
    <a href={href} onClick={onClick} className={className} target="_blank">
      {children}
    </a>
  );
}


export default ExternalLink;