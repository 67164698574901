import React, { useState, useEffect, useCallback } from 'react';
import { styled as StyledMui } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Button } from '../';
import { Text } from '../Texts';
import { COLORS } from 'src/style/colors';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import useMediaQuery from 'src/style/useMediaQuery';

const AccordionWrapper = styled.div`
  position: relative;
`;

const Accordion = StyledMui((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderBottom: `1px solid ${COLORS.PRIMARY}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = StyledMui((props) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon />} {...props} />
))(({ theme }) => ({
  backgroundColor: `${COLORS.WHITE}`,
  flexDirection: 'row-reverse',
  padding: '18px 0px',

  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(90deg)',
    border: '1px solid black',
    borderRadius: '100%',
    padding: '8px',
    'min-width': '32px',
    'min-height': '32px',
    'margin-inline-end': '18px',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(270deg)',
    border: '1px solid black',
  },
  '& .MuiAccordionSummary-content': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  '& .MuiSvgIcon-root': {
    width: '24px',
    height: '24px',
    [theme.breakpoints.down('md')]: {
      width: '14px',
      height: '14px',
    },
  },
}));

const AccordionDetails = StyledMui(MuiAccordionDetails)(({ theme }) => ({
  padding: '12px 0px',
  borderTop: `1px solid ${COLORS.PRIMARY}`,
}));

const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

const ButtonWrapperTop = styled.a`
  display: block;
  text-decoration: none;
  position: absolute;
  z-index: 1;
  top: 30px;
  right: 0;
`;

const ButtonWrapperBottom = styled.a`
  display: block;
  text-decoration: none;
  margin-bottom: 15px;
`;

const JobIconImg = styled.img`
  width: 50px;
  height: 50px;
  ${down('mobile')} {
    width: 30px;
    height: 30px;
  }
`;

const JobDetailsWrapper = styled.div`
  padding-inline-start: 60px;
  ${down('mobile')} {
    padding-inline-start: 0;
  }
`;

const JobDetailsContent = styled.div`
  margin-bottom: 30px;
  /* p {
    font-size: 18px;
  }
  li {
    font-size: 18px;
  }
  ${down('mobile')} {
    p {
      font-size: 14px;
    }
    li {
      font-size: 14px;
    }
  } */
`;

const CustomizedAccordion = ({
  jobTitle,
  jobDetails,
  jobIcon,
  jobUrl,
  accordionIndex,
}) => {
  const [expanded, setExpanded] = useState(false);
  const { isLaptopOrAbove, isMini } = useMediaQuery();

  const handleChange = useCallback(
    (event, newExpanded) => {
      const panel = `panel${accordionIndex}`;
      setExpanded(newExpanded ? panel : false);
    },
    [expanded]
  );

  return (
    <AccordionWrapper>
      {isLaptopOrAbove && (
        <ButtonWrapperTop href={jobUrl} target="_blank">
          <Button
            variant="contained"
            text="Apply"
            color="secondary"
            sx={{ fontSize: '20px' }}
            size="large"
          />
        </ButtonWrapperTop>
      )}
      <Accordion
        onChange={handleChange}
        expanded={expanded === `panel${accordionIndex}`}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <TitleWrapper>
            {/* <JobIconImg src={jobIcon} alt="react-icon" /> */}
            <Text
              text={jobTitle}
              variant={isMini ? 'h5' : 'h4'}
              isMedium
            ></Text>
          </TitleWrapper>
        </AccordionSummary>
        <AccordionDetails>
          <JobDetailsWrapper>
            {jobDetails.map((detail) => (
              <>
                <Text
                  text={detail.name}
                  variant="h5"
                  sx={{ marginBottom: '20px' }}
                  color={COLORS.GRAY}
                />
                <Text>
                  <JobDetailsContent
                    dangerouslySetInnerHTML={{ __html: detail.value }}
                  />
                </Text>
              </>
            ))}
            <ButtonWrapperBottom href={jobUrl} target="_blank">
              <Button
                variant="contained"
                text="Apply"
                color="secondary"
                sx={{ fontSize: '20px' }}
                size="large"
              />
            </ButtonWrapperBottom>
          </JobDetailsWrapper>
        </AccordionDetails>
      </Accordion>
    </AccordionWrapper>
  );
};
export default CustomizedAccordion;
