import React, { useCallback } from "react";
import styled from "styled-components";
import { useI18next, Trans } from 'gatsby-plugin-react-i18next';
import { Dropdown } from 'src/components/Common';

const options = [
  { value: 'en', label: 'English' },
  { value: 'he', label: 'Hebrew' },
]

const Root = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Links = styled.span``; // For SEO

const Language = () => {
  const { language, changeLanguage, siteUrl, originalPath } = useI18next();
  const enLink = `${siteUrl}${originalPath}`;
  const heLink = `${siteUrl}/he${originalPath}`;

  const handleChange = useCallback((event) => {
    changeLanguage(event.target.value);
  }, [])

  return (
    <Root>
      <Dropdown options={options} value={language} onChange={handleChange} sx={{ width: 150 }} />
      <Links>
        <a href={enLink} hreflang={language} hidden><Trans>English</Trans></a>
        <a href={heLink} hreflang={language} hidden><Trans>Hebrew</Trans></a>
      </Links>
    </Root>
  )
}

export default Language;
