import React from "react";
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { COLORS } from 'src/style/colors';

const InputStyled = styled(InputBase)`
  color: black;
  padding: 8px 20px;
  border-radius: 10px;

  &.Mui-focused {
    outline: ${props => `2px solid ${props.outlineColor}`};
  }
`;

const Input = ({ className, placeholder, value, onChange, required, type, name, size, autoFocus }) => {
  return (
    <InputStyled
      className={className}
      label="Email"
      variant="outlined"
      placeholder={placeholder}
      outlineColor={COLORS.PRIMARY}
      value={value}
      onChange={onChange}
      required={required}
      type={type}
      name={name}
      size={size}
      autoFocus={autoFocus}
    />
  )
}

export default Input;
