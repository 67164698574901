import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { only, between } from "styled-breakpoints";
import { Text, MiddleLayout } from "src/components/Common";
import AppsforceSoldier from "/src/assets/soldiers/appsforce_soldier.svg";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ContactUsFrom from "./ContactUsFrom";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import { useInView } from "react-intersection-observer";

const Root = styled.div`
  background-image: url("/images/army_bright.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px 10px 0 10px;
  position: relative;
  overflow: hidden;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  gap: 50px;
  width: 100%;

  ${between("mini", "mobile")} {
    width: 100%;
    flex-direction: column-reverse;
    gap: 0;
  }
`;

const LeftSide = styled.div`
  width: 70%;

  ${between("mini", "mobile")} {
    width: 100%;
  }
`;

const DetailsWrapper = styled.div`
  display: flex;
  gap: 50px;

  ${only("mini")} {
    margin-bottom: 50px;
  } ;
`;

const Header = styled(Text)``;

const PhonesWrapper = styled.div``;

const Subtitle = styled.div`
  margin: 15px 0 20px 0;

  ${between("mini", "mobile")} {
    min-height: 60px;
  }
`;

const MobileHeader = styled.div`
  display: none;

  ${between("mini", "mobile")} {
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }
`;

const HeaderWrapper = styled.div`
  display: block;

  ${between("mini", "mobile")} {
    display: none;
  }
`;

const AppsforceSoldierWrapper = styled.div`
  width: 160px;
  transform: translateY(5px);

  ${only("mini")} {
    display: none;
  }

  ${only("mobile")} {
    width: 100px;
  } ;
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  ${only("mini")} {
    width: 100%;
  } ;
`;

const Detail = styled.div`
  display: flex;
  gap: 10px;
`;

const PhoneNumber = styled.div`
  margin-bottom: 8px;
`;

const DetailLink = styled(OutboundLink)`
  text-decoration: underline;
  color: var(--dark) !important;

  &,
  &:visited,
  &:hover,
  &:active {
    color: var(--dark) !important;
  }
`;

const renderMobileHeader = () => (
  <MobileHeader>
    <Header variant="h3" isBold text="Lets Talk" color="primary" />
    <Subtitle>
      <Text variant="h6" text="Lets Talk Description" />
    </Subtitle>
  </MobileHeader>
);

const LetsTalk = () => {
  const { ref: inViewRef, inView } = useInView();
  const [startType, setStartType] = useState(false);

  useEffect(() => {
    if (inView) setStartType(true);
  }, [inView]);

  return (
    <Root id="contentUs" ref={inViewRef}>
      <MiddleLayout center>
        <MobileHeader key={`LetsTalk-${startType}-text-mobile`}>
          <Header
            variant="h3"
            isBold
            text="Lets Talk"
            color="primary"
            animation={{ name: "flip" }}
          />
          <Subtitle>
            <Text
              variant="h6"
              text="Lets Talk Description"
              animation={{ name: "typist", speed: 50 }}
            />
          </Subtitle>
        </MobileHeader>
        <Content>
          <LeftSide>
            <HeaderWrapper key={`LetsTalk-${startType}-text`}>
              <Header
                variant="h3"
                isBold
                text="Lets Talk"
                color="primary"
                animation={{ name: "flip" }}
              />
              <Subtitle>
                <Text
                  variant="body1"
                  text="Lets Talk Description"
                  animation={{ name: "typist", speed: 50 }}
                />
              </Subtitle>
            </HeaderWrapper>
            <DetailsWrapper>
              <Details>
                <Detail>
                  <PhoneIphoneIcon color="primary" fontSize="large" />
                  <PhonesWrapper>
                    <Text variant="body1" component="div">
                      <DetailLink href="tel:052-3954991">
                        <PhoneNumber>052-3954991</PhoneNumber>
                      </DetailLink>
                    </Text>
                    <Text variant="body1" component="div">
                      <DetailLink href="tel:052-6004446">
                        052-6004446
                      </DetailLink>
                    </Text>
                  </PhonesWrapper>
                </Detail>
                <Detail>
                  <MailOutlineIcon color="primary" fontSize="large" />
                  <Text variant="body1" component="div">
                    <DetailLink href="mailto:dev@appsforce.io">
                      dev@appsforce.io
                    </DetailLink>
                  </Text>
                </Detail>
                <Detail>
                  <LocationOnIcon color="primary" fontSize="large" />
                  <Text
                    variant="body1"
                    whiteSpace="pre"
                    text="Appforce Address"
                    component="div"
                  />
                </Detail>
              </Details>
              <AppsforceSoldierWrapper>
                <AppsforceSoldier />
              </AppsforceSoldierWrapper>
            </DetailsWrapper>
          </LeftSide>
          <ContactUsFrom buttonId="FooterContactUsButton" />
        </Content>
      </MiddleLayout>
    </Root>
  );
};

export default LetsTalk;
