import React, { useEffect } from 'react';
import Navbar from '../components/Navbar/Navbar';
import LetsTalk from '../components/LetsTalk';
import Footer from '../components/Footer';
import FloatContactUs from '../components/FloatContactUs';
import styled from 'styled-components';
import { useI18next } from 'gatsby-plugin-react-i18next';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { ConditionalWrapper } from 'src/components/Common';
import '../style/app.css';

const Content = styled.div`
  padding-top: 70px;
`;

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [rtlPlugin],
});

const HIDE_LETS_TALK_PAGES = [
  '/contact/',
  '/he/contact/',
  '/contact/success/',
  '/he/contact/success/',
];

const PageLayout = ({ children }) => {
  const { i18n, language, changeLanguage } = useI18next();

  const hideLetsTalk = () => {
    if (typeof window !== 'undefined') {
      return HIDE_LETS_TALK_PAGES.includes(window.location.pathname);
    }
    return false;
  };

  useEffect(() => {
    // const isVisit = localStorage.getItem('isVisit');
    // if (!isVisit) {
    //   changeLanguage('en');
    //   localStorage.setItem('isVisit', true);
    // }
  }, []);

  const wrapRtl = (wrapChildren) => (
    <CacheProvider value={cacheRtl}>{wrapChildren}</CacheProvider>
  );

  return (
    <ConditionalWrapper condition={language === 'he'} wrap={wrapRtl}>
      <div id="side" dir={i18n?.dir()}>
        <Navbar />
        <Content>{children}</Content>
        <FloatContactUs />
        {!hideLetsTalk() && <LetsTalk />}
        <Footer />
      </div>
    </ConditionalWrapper>
  );
};

export const BrowserPageLayout = PageLayout;
export const SsrPageLayout = PageLayout;
