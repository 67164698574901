import React from 'react';
import styled from 'styled-components';
import FooterLink from './FooterLink';
import Link from '../Common/Link/Link';
import Language from './Language';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GoogleIcon from '@mui/icons-material/Google';
import AppsforceLogo from 'src/assets/logo-horiz-all-blue.svg';
import { Text } from 'src/components/Common';
import { only, between } from 'styled-breakpoints';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { URL } from 'src/url';
import { useI18next } from 'gatsby-plugin-react-i18next';

const Root = styled.footer`
  padding: 70px 100px 5px 100px;
  position: relative;

  ${only('laptop')} {
    padding: 60px 40px 5px 40px;
  }

  ${between('mini', 'mobile')} {
    padding: 35px 20px 5px 20px;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 50px;
  width: 100%;

  ${between('mini', 'mobile')} {
    gap: 30px;
    flex-direction: column;
    align-items: center;
  }
`;

const LinksList = styled.ul`
  display: flex;
  gap: 15px;
  flex-direction: column;
  margin-bottom: 20px;
  /* display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  gap: 50px;
  width: 100%;

  ${between('mini', 'mobile')} {
    gap: 20px;
    flex-direction: column;
  } */
`;

const BottomLine = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-top: 0;

  ${between('mini', 'mobile')} {
    margin-top: 20px;
  }
`;

const LogoWrapper = styled.div`
  width: 200px;

  ${between('mini', 'mobile')} {
    width: 140px;
  }
`;

const SocialMedia = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const Rights = styled(Text)`
  text-align: center;
  display: block;
  margin-top: 40px !important;
`;

const LinksListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 160px;

  ${between('mini', 'mobile')} {
    gap: 20px;
  }
`;

const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const SocialMediaLink = styled(OutboundLink)`
  color: inherit;
  width: 35px;
  height: 35px;
  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    color: var(--primary);
  }
`;

const Footer = () => {
  const { language } = useI18next();
  return (
    <Root id="footer">
      <script src="https://cdn.enable.co.il/licenses/enable-L12538roashci09x-0822-30742/init.js" />
      <Content>
        <LogoWrapper>
          <Link to="/">
            <AppsforceLogo />
          </Link>
        </LogoWrapper>
        <LinksListWrapper>
          <LinksList>
            <FooterLink isBold link={URL.HOME} text="Home" />
            <FooterLink link={URL.CAREERS} text="Careers" />
            {language === 'he' && <FooterLink link={URL.BLOGS} text="Blog" />}
            <FooterLink link={URL.CONTACT} text="Contact Us" />
            <FooterLink link={URL.ABOUT} text="About" />
          </LinksList>
          <LinksList>
            <FooterLink isBold link={URL.SERVICES} text="Services" />
            <FooterLink link={URL.FRONT_END_SERVICE} text="Front End Develop" />
            <FooterLink
              link={URL.FULL_STACK_SERVICE}
              text="Full Stack Develop"
            />
            <FooterLink link={URL.MOBILE_SERVICE} text="Mobile Develop" />
            <FooterLink
              link={URL.SAAS_CONSULTING_SERVICE}
              text="SaaS Consulting"
            />
          </LinksList>
        </LinksListWrapper>
        <RightSide>
          <Language />
          <SocialMedia>
            {/* <FacebookOutlinedIcon color="darkl" fontSize="large" /> */}
            <SocialMediaLink
              target="_blank"
              href="https://www.linkedin.com/company/appsforceio"
            >
              <LinkedInIcon color="darkl" fontSize="large" />
            </SocialMediaLink>
            <SocialMediaLink
              target="_blank"
              href="https://goo.gl/maps/a9WSbVhSjE4H8ysx8"
            >
              <GoogleIcon color="darkl" fontSize="large" />
            </SocialMediaLink>
            {/* <InstagramIcon color="darkl" fontSize="large" /> */}
          </SocialMedia>
        </RightSide>
      </Content>
      <Rights variant="body2" color="darkl" text="All rights reserved" />
    </Root>
  );
};

export default Footer;
