import { css } from "@mui/material/styles";

export const COLORS = {
  PRIMARY: "#000089",
  SECONDARY: "#9b00ff",
  WHITE: "#fff",
  BLACK: "#000",
  DARK_L: "#2B2A35",
  GRAY: "#7d7d85",
  GREY_2: "#949494",
};

export const linearTextColor = css`
  color: #004273;
  background-color: #004273;
  background-image: var(--linear);
  background-position: 100% 0%;
  background-clip: text;
  -webkit-text-fill-color: transparent;
`;
