import React from "react";
import styled from "styled-components";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { between } from 'styled-breakpoints';

export const Root = styled.div`
  width: 220px;
  display: flex;
  justify-content: end;
  align-items: center;

  ${between('mini', 'mobile')} {
    margin: 0 10px;
    width: auto;
  };
`;

export const WhatsAppWrapper = styled.a`
  display: block;
  background-color: #4ed24c;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  cursor: pointer;

  ${between('mini', 'mobile')} {
    width: 37px;
    height: 37px;
  };

  svg {
    font-size: 28px;
    fill: white;
  }
`;

const WhatsAppButton = () => (
  <Root id="WhatsAppButton1">
    <WhatsAppWrapper target="_blank" href="https://api.whatsapp.com/send?phone=972523954991" id="WhatsAppButton2">
      <WhatsAppIcon id="WhatsAppButton3" />
    </WhatsAppWrapper>
  </Root>
);

export default WhatsAppButton;
