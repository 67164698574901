import React from "react"
import { styled } from '@mui/material/styles';
import MuiPopover from '@mui/material/Popover';
import { useI18next } from 'gatsby-plugin-react-i18next';

const MuiPopoverStyled = styled(MuiPopover)`
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .MuiPopover-paper {
    border: 5px solid var(--light-1);
    border-radius: 20px;
  }
`;

const Popover = ({ id, open, anchorEl, onClose, children }) => {
  const { i18n } = useI18next();

  return (
    <MuiPopoverStyled
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      <div dir={i18n?.dir()}>
        {children}
      </div>
    </MuiPopoverStyled>
  );
};

export default Popover;
