import React from 'react';
import { styled, css } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import Flip from 'react-reveal/Flip';
import useMediaQuery from 'src/style/useMediaQuery';
import Typist from 'react-typist';

const getFontWeight = ({ isExtraBold, isBold, isLight, isMedium }) => {
  if (isExtraBold) return 700;
  if (isBold) return 500;
  if (isMedium) return 400;
  if (isLight) return 300;
  return null;
};

const colorAnimation = css`
  transition: background-position 1s cubic-bezier(0.45, 0, 0.55, 1);
  background-color: currentColor;
  background-image: linear-gradient(
    90deg,
    #6600cc 0%,
    #6600cc 46%,
    currentColor 54%,
    currentColor 100%
  );
  background-position: 100% 0;
  background-clip: text;
  -webkit-background-clip: text;
  background-size: 220% 100%;
  -webkit-text-fill-color: transparent;
`;

const colorHoverAnimation = css`
  text-decoration: none;
  background-color: var(--secondary);
  background-position: 0% 0%;
`;

const TypographyStyled = styled(Typography)`
  ${(props) =>
    props.color === 'darkl' &&
    css`
      color: #000;
      /* color: #2B2A35; */
    `};

  ${(props) =>
    props.color === 'lighDark' &&
    css`
      color: #2b2a35;
    `};

  ${(props) =>
    props.hoverAnimation &&
    css`
      ${colorAnimation}

      &:hover {
        ${colorHoverAnimation}
      }
    `};

  ${(props) =>
    props.withAnimation &&
    css`
      ${colorAnimation}
    `};

  ${(props) =>
    props.activeAnimation &&
    css`
      ${colorHoverAnimation}
    `};
`;

const Text = ({
  text,
  variant,
  children,
  className,
  isBold,
  isExtraBold,
  isLight = true,
  withAnimation,
  component,
  isMedium,
  color,
  whiteSpace,
  isBlock,
  fontSize,
  onClick,
  isPointer,
  sx,
  hoverAnimation,
  activeAnimation,
  id,
  animation,
}) => {
  const { language } = useI18next();
  const { t } = useTranslation();
  const { isMini } = useMediaQuery();

  let data = t(text);
  if (animation?.name === 'typist') {
    data = (
      <Typist cursor={{ blink: true }} avgTypingDelay={animation.speed}>
        {data}
      </Typist>
    );
  }

  if (animation?.name === 'flip') {
    const position = animation.position
      ? { [animation.position]: true }
      : { bottom: true };
    data = (
      <Flip {...position} cascade={!isMini} delay={animation.delay}>
        {data}
      </Flip>
    );
  }

  return (
    <TypographyStyled
      variant={variant}
      className={className}
      color={color}
      onClick={onClick}
      component={component}
      hoverAnimation={language === 'en' && hoverAnimation}
      activeAnimation={language === 'en' && activeAnimation}
      withAnimation={language === 'en' && withAnimation}
      id={id}
      sx={{
        fontWeight: getFontWeight({ isExtraBold, isBold, isLight, isMedium }),
        whiteSpace,
        display: isBlock && 'block',
        fontSize,
        cursor: isPointer && 'pointer',
        ...sx,
      }}
    >
      {text ? data : children}
    </TypographyStyled>
  );
};

export default Text;
