import * as React from "react";
import styled, { css } from "styled-components";
import { Text, MiddleLayout } from 'src/components/Common';
import { COLORS } from 'src/style/colors';
import { only } from 'styled-breakpoints';

const Root = styled.div`
  width: 100vw;
  height: 750px;
  background-image: url('/images/wave_blue.svg');
  background-repeat: no-repeat;
  background-size: cover;

  ${only('mobile')} {
    height: 400px;
  };

  ${only('laptop')} {
    height: 580px;
  };
`;

const MiddleLayoutStyled = styled(MiddleLayout)`
  display: flex;

  ${props => props.size === 'small' && css`
    align-items: center;
    height: 100%;
    max-height: 600px;

      ${only('laptop')} {
        max-height: 430px;
      };
    `};

  ${only('mini')} {
    flex-direction: column;
    text-align: center;
  };
`;

const TextsWrapper = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-self: center;
`;

const ServicesSoldierWrapper = styled.div`
  svg {
    width: 2500px;

    ${only('mini')} {
      width: 500px;
    };

    ${only('mobile')} {
      width: 1200px;
    };

    ${props => props.size === 'small' && css`
      width: 900px;

      ${only('mini')} {
        margin-top: 20px;
        width: 400px;
      };
    `};
  }
`;

const TopSection = ({ header, description, Image, size }) => {
  return (
    <Root>
      <MiddleLayoutStyled size={size}>
        <TextsWrapper>
          <Text text={header} variant="h2" isBold color={COLORS.WHITE} component="h1" />
          <Text text={description} variant="h6" color={COLORS.WHITE} component="h2" />
        </TextsWrapper>
        <ServicesSoldierWrapper size={size}>
          <Image />
        </ServicesSoldierWrapper>
      </MiddleLayoutStyled>
    </Root>
  );
}

export default TopSection;
