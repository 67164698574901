import React, { useState } from 'react';
import styled from 'styled-components';
import { Trans } from 'gatsby-plugin-react-i18next';
import { Text, Link } from 'src/components/Common';
import { URL } from 'src/url';
import DiamondIcon from '/src/assets/servicesIcons/diamond.svg';
import AbcIcon from '/src/assets/servicesIcons/abc.svg';
import MazeIcon from '/src/assets/servicesIcons/maze.svg';
import MobileIcon from '/src/assets/servicesIcons/mobile.svg';

const SubMenu = styled.div`
  position: absolute;
  z-index: 12;
  visibility: hidden;
  padding-top: 10px;
  left: -45px;
  cursor: default;
  transition: all 200ms linear;
  transform: perspective(200px) rotateX(-15deg) rotateY(0deg) rotateZ(0deg);
  transform-origin: top center;
  opacity: 0;
  width: max-content;
`;

const SubMenuInner = styled.div`
  position: relative;
  z-index: 1;
  top: calc(100% + 1em);
  margin-top: 1.2rem;
  padding: 15px 12px;
  border-radius: 1.2rem;
  background-color: #fff;
  box-shadow: 0 2px 6px rgb(13 23 75 / 25%);
  display: flex;
  flex-direction: column;
  gap: 0px;
`;

const Root = styled.li`
  position: relative;

  &:hover {
    ${SubMenu} {
      visibility: visible;
      transform: rotateX(0deg);
      opacity: 1;
    }
  }
`;

const ArrowDownIcon = styled.span`
  border: solid;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: translateY(-2px) rotate(45deg);
`;

const ServicesLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const MenuItem = styled.div`
  padding: 8px 15px;
  cursor: pointer;
  transition: all 0.2s;
  border-radius: 10px;

  &:hover {
    background-color: var(--light-2);
  }

  a:hover {
    color: inherit;
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  svg {
    width: 25px;
    height: 25px;
  }
`;

const ServicesMenu = () => {
  const [key, setKey] = useState(0);

  const onKeyChange = () => {
    setKey(key + 1);
  };

  const renderMenuItem = (label, link, Icon) => (
    <MenuItem>
      <Link to={link} onClick={onKeyChange} activeStyle>
        <Item>
          <Icon />
          <Text variant="body1" text={label} />
        </Item>
      </Link>
    </MenuItem>
  );

  return (
    <Root key={`servies-menu-${key}`}>
      <div>
        <Link to={URL.SERVICES} activeStyle partiallyActive>
          <Text variant="h6" component="div">
            <ServicesLink>
              <Trans>Services</Trans>
              <ArrowDownIcon />
            </ServicesLink>
          </Text>
        </Link>
      </div>
      <SubMenu>
        <SubMenuInner>
          {renderMenuItem(
            'Front End Develop',
            URL.FRONT_END_SERVICE,
            DiamondIcon
          )}
          {renderMenuItem(
            'Full Stack Develop',
            URL.FULL_STACK_SERVICE,
            AbcIcon
          )}
          {renderMenuItem('Mobile Develop', URL.MOBILE_SERVICE, MobileIcon)}
          {renderMenuItem(
            'SaaS Consulting',
            URL.SAAS_CONSULTING_SERVICE,
            MazeIcon
          )}
        </SubMenuInner>
      </SubMenu>
    </Root>
  );
};

export default ServicesMenu;
