export const URL = {
  HOME: '/',
  CONTACT: '/contact/',
  ABOUT: '/about/',
  SERVICES: '/services/',
  FRONT_END_SERVICE: '/services/front-end/',
  FULL_STACK_SERVICE: '/services/full-stack/',
  SAAS_CONSULTING_SERVICE: '/services/saas-consulting/',
  UI_UX_DESIGN_SERVICE: '/services/ui-ux-design/',
  MOBILE_SERVICE: '/services/mobile/',
  CAREERS: '/careers/',
  BLOGS: '/blog/',
  REACT_VS_ANGULAR_BLOG: '/blog/react-vs-angular/',
  WEB_DEVELOPMENT_PROCESSES_BLOG: '/blog/web-development-processes/',
  DESIGN_RULRS_BLOG: '/blog/design-rules/',
  EMPHASIS_TO_CONSIDER_WHEN_DESIGNING_SAAS_APP_BLOG:
    '/blog/principles-on-designing-saas-app/',
  BENEFITS_OF_WORKING_WITH_SOFTWARE_COMPANY_BLOG:
    '/blog/benefits-of-working-with-software-company/',
  SOLUTIONS_FOR_SOFTWARE_DEVELOPMENT_BLOG:
    '/blog/solutions-for-software-development/',
  HOW_TO_DO_SOFTWARE_DESIGN_RIGHT_BLOG:
    '/blog/how-to-do-software-design-right/',
  STAGES_ON_AGILE_DEVELOPMENT_BLOG: '/blog/stages-on-agile-development/',
  STAGES_ON_AGILE_DEVELOPMENT_BLOG: '/blog/stages-on-agile-development/',
  LEADING_TRENDS_IN_WEB_APPLICATION_DEVELOPMENT:
    '/blog/leading-trends-in-web-application-development/',
  SOFTWARE_DEVELOPMENT_TOOLS_THAT_MUST_BE_FAMILIAR:
    '/blog/software-development-tools-that-must-be-familiar/',
  WINNING_SITES_BUILT_USING_REACT: '/blog/winning-sites-built-using-react/',
  FREQUENTLY_ASKED_QUESTIONS_AND_EXAMPLES_OF_SAAS:
    '/blog/frequently-asked-questions-and-examples-of-saas/',
  TYPICAL_FRONT_END_DAY: '/blog/typical-front-end-day/',
  WHAT_DOSE_FULL_STACK_DEVELOPER: '/blog/what-dose-full-stack-developer/',
};
