import useMQ from "@mui/material/useMediaQuery";

const useMediaQuery = () => {
  const isMini = useMQ("(max-width:767px)");
  const isMobile = useMQ("(min-width:768px) and (max-width:1023px)");
  const isLaptop = useMQ("(min-width:1024px) and (max-width:1199px)");
  const isDesktop = useMQ("(min-width:1200px) and (max-width:1459px)");
  const isDesktopL = useMQ("(min-width:1460px)");
  const isMobileOrMini = isMobile || isMini;
  const isLaptopOrAbove = isLaptop || isDesktop || isDesktopL;
  return { isMini, isMobile, isMobileOrMini, isLaptop, isDesktop, isDesktopL, isLaptopOrAbove };
};

export default useMediaQuery;
