import React from "react";
import styled from "styled-components";
import { Link } from 'src/components/Common';
import AppsforceLogo from '../../assets/logos/logo-primary-text.svg';
import { only } from 'styled-breakpoints';

const Root = styled.div`
  width: 160px;
  height: 48px;

  ${only('mini')} {
    width: 130px;
    height: 33px;
  };

  ${only('mobile')} {
    width: 140px;
    height: 36px;
  };
`;

const Logo = () => {
  return (
    <Root>
      <Link to="/">
        <AppsforceLogo />
      </Link>
    </Root>
  )
}

export default Logo;
