import React from "react";
import styled, { css } from "styled-components";
import { only } from 'styled-breakpoints';

const center = css`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Root = styled.div`
  margin: 0 auto;
  width: 1350px;
  max-width: 100vw;
  ${props => props.center && center};

  ${only('mini')} {
    width: auto;
    padding: 0 15px;
  };

  ${only('mobile')} {
    width: 750px;
  };

  ${only('laptop')} {
    width: 970px;
  };

  ${only('desktop')} {
    width: 1170px;
  };
`;

const MiddleLayout = ({ children, center, className }) => (
  <Root center={center} className={className}>
    {children}
  </Root>
);

export default MiddleLayout;
