import React from "react";
import { styled, css } from '@mui/material/styles';
import MaterialButton from '@mui/material/Button';
import { Trans } from 'gatsby-plugin-react-i18next';

const ButtonStyled = styled(MaterialButton)`
  display: block;
  border-radius: 45px;

  &.Mui-disabled	 {
    background-color: var(--light-1) !important;
    cursor: not-allowed !important;

    ${props => props.disabledColor === 'primary' && css`
      background-color: var(--primary) !important;
      color: var(--white);
    `};

    ${props => props.disabledColor === 'info' && css`
      background-color: var(--white) !important;
      color: var(--secondary);
    `};
  }
`;

const Button = ({ className, variant, children, text, color, size, sx, onClick, type, disabled, disabledColor, id }) => {
  return (
    <ButtonStyled
      className={className}
      variant={variant}
      color={color}
      size={size}
      sx={sx}
      onClick={onClick}
      type={type}
      disabled={disabled}
      disabledColor={disabledColor}
      id={id}
    >
      {text ? <Trans>{text}</Trans> : children}
    </ButtonStyled >
  )
}

export default Button;
