import React from "react";
import { styled } from '@mui/material/styles';
import MuiMenuItem from '@mui/material/MenuItem';

const MuiMenuItemStyled = styled(MuiMenuItem)`
  padding: 10px 25px;
`;

const MenuItem = ({ onClick, children }) => {
  return (
    <MuiMenuItemStyled onClick={onClick}>
      {children}
    </MuiMenuItemStyled>
  );
};

export default MenuItem;