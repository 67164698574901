import React, { useState, useCallback } from "react";
import { Root, LinksList, MobileMenuWrapper, DesktopWhatsApp, MobileWhatsApp, ContactUs } from "./Navbar.style";
import NavbarLink from "./NavBarLink";
import ServicesMenu from "./ServicesMenu";
import MobileMenu from "./MobileMenu";
import WhatsAppButton from "./WhatsAppButton";
import Logo from "./Logo";
import { useWindowScroll } from "react-use";
import { Squash as Hamburger } from "hamburger-react";
import { COLORS } from "src/style/colors";
import { URL } from "src/url";
import { useI18next } from "gatsby-plugin-react-i18next";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState();
  const { y } = useWindowScroll();
  const { language } = useI18next();

  const onCloseMenu = useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  return (
    <Root showShadow={y !== 0}>
      <Logo />
      <LinksList>
        <NavbarLink link={URL.HOME} text="Home" activeStyle />
        <ServicesMenu />
        <NavbarLink link={URL.CAREERS} text="Careers" activeStyle />
        {language === "he" && <NavbarLink link={URL.BLOGS} text="Blog" activeStyle partiallyActive />}
        <NavbarLink link={URL.ABOUT} text="About" activeStyle />
        <NavbarLink link={URL.CONTACT} text="Contact" activeStyle />
      </LinksList>
      <DesktopWhatsApp>
        <WhatsAppButton />
      </DesktopWhatsApp>
      <MobileMenuWrapper>
        <MobileWhatsApp>
          <WhatsAppButton />
        </MobileWhatsApp>
        <Hamburger toggled={isMenuOpen} toggle={setIsMenuOpen} color={COLORS.PRIMARY} />
        {isMenuOpen && <MobileMenu isMenuOpen={isMenuOpen} onCloseMenu={onCloseMenu} language={language} />}
      </MobileMenuWrapper>
    </Root>
  );
};

export default Navbar;
