import React from "react";
import ExternalLinkG from 'src/components/Common/Link/ExternalLink';
import { styled } from '@mui/material/styles';
import { Link as LinkG } from 'gatsby-plugin-react-i18next';

export const GatsbyLink = styled(LinkG)(
  ({ theme, active }) => `
  color: ${active ? theme.palette.primary.main : undefined} !important;
  text-decoration: ${active ? 'underline' : 'none'};
  transition: 'all 10s';

  :hover {
    color: ${theme.palette.primary.main};
  }
`,
);

export const ExternalLink = styled(ExternalLinkG)(
  ({ theme, active }) => `
  color: ${active ? theme.palette.primary.main : theme.palette.text.primary} !important;
  text-decoration: ${active ? 'underline' : 'none'} !important;

  :hover {
    color: ${theme.palette.primary.main};
  }
`,
);


const Link = ({ to, external, children, onClick, className, activeStyle, active, partiallyActive }) =>
  !external ? (
    <GatsbyLink
      to={to}
      activeClassName={activeStyle && "active-link"}
      partiallyActive={partiallyActive}
      onClick={onClick}
      className={className}
      active={active}
    >
      {children}
    </GatsbyLink>
  ) : (
    <ExternalLink
      href={to}
      onClick={onClick}
      className={className}
      active={active}
    >
      {children}
    </ExternalLink>
  );

export default Link;
