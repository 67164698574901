import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { COLORS } from '../colors';

export const theme = responsiveFontSizes(
  createTheme({
    direction: 'rtl',
    typography: {
      fontFamily: ['Poppins', 'Rubik'].join(','),
      h1: {
        fontFamily: 'Rubik',
      },
      h2: {
        fontFamily: 'Rubik',
      },
      h3: {
        fontFamily: 'Rubik',
      },
      h4: {
        fontFamily: 'Rubik',
      },
      body1: {
        fontSize: 18,
      },
      body2: {
        fontSize: 16,
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1024,
        lg: 1200,
        xl: 1460,
      },
    },
    palette: {
      primary: {
        // light: will be calculated from palette.primary.main,
        main: COLORS.PRIMARY,
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        main: COLORS.SECONDARY,
      },
      text: {
        secondary: {
          main: COLORS.COLOR,
        },
      },
      info: {
        main: COLORS.WHITE,
        contrastText: COLORS.SECONDARY,
      },
      contrastThreshold: 3,
      tonalOffset: 0.2,
    },
  }),
  { breakpoints: ['xs', 'sm', 'md', 'lg', 'xl'] }
);

theme.typography.h1 = {
  fontFamily: 'Rubik',
  fontSize: '5rem',

  [theme.breakpoints.only('lg')]: {
    fontSize: '4.8rem',
  },

  [theme.breakpoints.only('md')]: {
    fontSize: '4.4rem',
  },

  [theme.breakpoints.only('sm')]: {
    fontSize: '3.5rem',
  },

  [theme.breakpoints.only('xs')]: {
    fontSize: '2.3rem',
  },
};

theme.typography.h2 = {
  fontFamily: 'Rubik',
  fontSize: '3.75rem',

  [theme.breakpoints.only('lg')]: {
    fontSize: '3.5rem',
  },

  [theme.breakpoints.only('md')]: {
    fontSize: '3.3rem',
  },

  [theme.breakpoints.only('sm')]: {
    fontSize: '2.8rem',
  },

  [theme.breakpoints.only('xs')]: {
    fontSize: '1.9rem',
  },
};

export const breakpointsTheme = {
  breakpoints: {
    mini: '0px',
    mobile: '768px',
    laptop: '1024px',
    desktop: '1200px',
    desktopL: '1460px',
  },
};
