import * as React from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import { Trans } from 'gatsby-plugin-react-i18next';

const CustomizedSelect = styled(Select)`
  fieldset {
    border-radius: 10px;
  }
`;

const Dropdown = ({ value, options, onChange, sx }) => {
  return (
    <Box sx={sx}>
      <FormControl fullWidth>
        <CustomizedSelect
          value={value}
          onChange={onChange}
          size="small"
        >
          {options.map((option) => (
            <MenuItem value={option.value}><Trans>{option.label}</Trans></MenuItem>
          ))}
        </CustomizedSelect>
      </FormControl>
    </Box>
  );
}

export default Dropdown;