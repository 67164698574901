import React from "react";
import styled from "styled-components";
import { Link, Text } from 'src/components/Common';

export const LinkItem = styled.li`
`;

const LinkText = styled(Text)`
  &:hover {
    text-decoration: underline;
  }
  text-underline-offset: 5px;
`;

const FooterLink = ({ link, isBold, text, className, variant }) => (
  <LinkItem className={className}>
    <Link to={link}>
      <LinkText isBold={isBold} text={text} variant={variant} color="darkl" variant="subtitle1" component="span" />
    </Link>
  </LinkItem>
)

export default FooterLink;
