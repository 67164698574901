// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-benefits-of-working-with-software-company-js": () => import("./../../../src/pages/blog/benefits-of-working-with-software-company.js" /* webpackChunkName: "component---src-pages-blog-benefits-of-working-with-software-company-js" */),
  "component---src-pages-blog-design-rules-js": () => import("./../../../src/pages/blog/design-rules.js" /* webpackChunkName: "component---src-pages-blog-design-rules-js" */),
  "component---src-pages-blog-frequently-asked-questions-and-examples-of-saas-js": () => import("./../../../src/pages/blog/frequently-asked-questions-and-examples-of-saas.js" /* webpackChunkName: "component---src-pages-blog-frequently-asked-questions-and-examples-of-saas-js" */),
  "component---src-pages-blog-how-to-do-software-design-right-js": () => import("./../../../src/pages/blog/how-to-do-software-design-right.js" /* webpackChunkName: "component---src-pages-blog-how-to-do-software-design-right-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-leading-trends-in-web-application-development-js": () => import("./../../../src/pages/blog/leading-trends-in-web-application-development.js" /* webpackChunkName: "component---src-pages-blog-leading-trends-in-web-application-development-js" */),
  "component---src-pages-blog-principles-on-designing-saas-app-js": () => import("./../../../src/pages/blog/principles-on-designing-saas-app.js" /* webpackChunkName: "component---src-pages-blog-principles-on-designing-saas-app-js" */),
  "component---src-pages-blog-react-vs-angular-js": () => import("./../../../src/pages/blog/react-vs-angular.js" /* webpackChunkName: "component---src-pages-blog-react-vs-angular-js" */),
  "component---src-pages-blog-software-development-tools-that-must-be-familiar-js": () => import("./../../../src/pages/blog/software-development-tools-that-must-be-familiar.js" /* webpackChunkName: "component---src-pages-blog-software-development-tools-that-must-be-familiar-js" */),
  "component---src-pages-blog-solutions-for-software-development-js": () => import("./../../../src/pages/blog/solutions-for-software-development.js" /* webpackChunkName: "component---src-pages-blog-solutions-for-software-development-js" */),
  "component---src-pages-blog-stages-on-agile-development-js": () => import("./../../../src/pages/blog/stages-on-agile-development.js" /* webpackChunkName: "component---src-pages-blog-stages-on-agile-development-js" */),
  "component---src-pages-blog-typical-front-end-day-js": () => import("./../../../src/pages/blog/typical-front-end-day.js" /* webpackChunkName: "component---src-pages-blog-typical-front-end-day-js" */),
  "component---src-pages-blog-web-development-processes-js": () => import("./../../../src/pages/blog/web-development-processes.js" /* webpackChunkName: "component---src-pages-blog-web-development-processes-js" */),
  "component---src-pages-blog-what-dose-full-stack-developer-js": () => import("./../../../src/pages/blog/what-dose-full-stack-developer.js" /* webpackChunkName: "component---src-pages-blog-what-dose-full-stack-developer-js" */),
  "component---src-pages-blog-winning-sites-built-using-react-js": () => import("./../../../src/pages/blog/winning-sites-built-using-react.js" /* webpackChunkName: "component---src-pages-blog-winning-sites-built-using-react-js" */),
  "component---src-pages-careers-index-js": () => import("./../../../src/pages/careers/index.js" /* webpackChunkName: "component---src-pages-careers-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-success-js": () => import("./../../../src/pages/contact/success.js" /* webpackChunkName: "component---src-pages-contact-success-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-front-end-js": () => import("./../../../src/pages/services/front-end.js" /* webpackChunkName: "component---src-pages-services-front-end-js" */),
  "component---src-pages-services-full-stack-js": () => import("./../../../src/pages/services/full-stack.js" /* webpackChunkName: "component---src-pages-services-full-stack-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-mobile-js": () => import("./../../../src/pages/services/mobile.js" /* webpackChunkName: "component---src-pages-services-mobile-js" */),
  "component---src-pages-services-saas-consulting-js": () => import("./../../../src/pages/services/saas-consulting.js" /* webpackChunkName: "component---src-pages-services-saas-consulting-js" */),
  "component---src-pages-services-ui-ux-design-js": () => import("./../../../src/pages/services/ui-ux-design.js" /* webpackChunkName: "component---src-pages-services-ui-ux-design-js" */)
}

