import React from "react";
import Text from './Text';

const Paragraph = ({ children }) => (
  <Text
    variant="body1"
    sx={{
      marginBottom: 2.5
    }}
  >
    {children}
  </Text>
);

export default Paragraph;