import React, { useState, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { Text } from 'src/components/Common';
import { Input, Textarea, Button } from 'src/components/Common';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import IconButton from '@mui/material/IconButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { only } from 'styled-breakpoints';

const Root = styled.div`
  width: 400px;
  min-height: 670px;
  padding: 25px 35px;
  border-radius: 50px;
  position: relative;

  ${only('mini')} {
    width: auto;
    min-height: auto;
    padding: 20px 25px;
  }

  ${(props) =>
    props.submitted &&
    css`
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    `};
`;

const Description = styled.div`
  margin-top: 10px;
  margin-bottom: 30px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const StyledInput = styled(Input)`
  width: 100%;
  border: 1px solid var(--light-1);
  margin-bottom: 20px;
`;

const MessageTextarea = styled(Textarea)`
  width: 100%;
  color: black;
  background-color: white;
  height: 200px;
  border: 1px solid var(--light-1);

  textarea {
    height: 170px !important;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;

  button {
    width: 100%;
  }
`;

const Success = styled.div`
  font-size: 120px;
`;

const CloseWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

const FloatContactUsForm = ({ onClose }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [text, setText] = useState('');
  const [name, setName] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      setSubmitting(true);
      await axios.post(
        'https://getform.io/f/4391f357-a1e2-4bea-a154-123d0a5fd321',
        {
          name,
          email,
          phone,
          subject: text,
        }
      );
      setSubmitted(true);
      setSubmitting(false);
    },
    [email, phone, text, name]
  );

  const handleEmailChange = useCallback(({ target }) => {
    setEmail(target.value);
  }, []);

  const handleTextChange = useCallback(({ target }) => {
    setText(target.value);
  }, []);

  const handlePhoneChange = useCallback(({ target }) => {
    setPhone(target.value);
  }, []);

  const handleNameChange = useCallback(({ target }) => {
    setName(target.value);
  }, []);

  const buttonText = () => {
    if (submitted) return 'Sent';
    if (submitting) return 'Sending...';
    return 'Send';
  };

  if (submitted)
    return (
      <Root submitted>
        <Text variant="h4" text="float contact us message send header" isBold />
        <Description>
          <Text
            variant="body2"
            text="float contact us message send description"
          />
        </Description>
        <Success>
          <CheckCircleIcon fontSize="inherit" color="success" />
        </Success>
      </Root>
    );

  return (
    <Root>
      <CloseWrapper>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </CloseWrapper>
      <Text
        variant="h5"
        text="float contact us header"
        isBold
        color="primary"
      />
      <Description>
        <Text
          variant="body2"
          text="float contact us description"
          whiteSpace="pre-line"
        />
      </Description>
      <Form onSubmit={handleSubmit}>
        <StyledInput
          placeholder={t('Required Name')}
          value={name}
          onChange={handleNameChange}
          name="name"
          size="small"
          autoFocus
          required
        />
        <StyledInput
          placeholder={t('Required Email')}
          value={email}
          onChange={handleEmailChange}
          type="email"
          name="email"
          size="small"
          required
        />
        <StyledInput
          placeholder={t('Required Phone')}
          value={phone}
          onChange={handlePhoneChange}
          type="number"
          name="phone"
          size="small"
          required
        />
        <MessageTextarea
          value={text}
          onChange={handleTextChange}
          placeholder={t('Hello Appsforce Team')}
          name="subject"
          size="small"
        />
        <ButtonWrapper>
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{ height: 50, fontSize: 19 }}
            type="submit"
            text={buttonText()}
            disabled={submitted || submitting}
            id="SubmitFloatContactUs"
          />
        </ButtonWrapper>
      </Form>
    </Root>
  );
};

export default FloatContactUsForm;
