import styled from "styled-components";
import { between } from 'styled-breakpoints';
import { Text } from 'src/components/Common';

export const Root = styled.nav`
  height: 70px;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
  transition: all .3s;
  box-shadow: ${props => props.showShadow ? "0px 4px 6px #ABABAB29" : null};
  position: fixed;
  top: 0;
  z-index: 1300;
  background-color: white;

  ${between('mini', 'mobile')} {
    padding: 0 15px;
  };
`;


export const LinksList = styled.ul`
  display: flex;
  gap: 70px;

  ${between('mini', 'mobile')} {
    display: none;
    width: 0;
    height: 0;
  };
`;

export const LinkItem = styled.li`
`;

export const DesktopWhatsApp = styled.div`
  ${between('mini', 'mobile')} {
    display: none;
    width: 0;
    height: 0;
  };
`;

export const MobileWhatsApp = styled.div`
  display: none;
  width: 0;
  height: 0;
  ${between('mini', 'mobile')} {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: auto;
  };
`;

export const Language = styled.div`
`;

export const MobileMenuWrapper = styled.span`
  display: none;

  ${between('mini', 'mobile')} {
    display: flex;
    justify-content: end;
  };
`;

export const ContactUs = styled(Text)`
  transition: all .2s;

  &:hover {
    color: var(--primary)
  }
`;
