import React, { useCallback, useState } from "react";
import styled, { css } from "styled-components";
import { Input, Textarea, Button } from 'src/components/Common';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import axios from 'axios';
import { only, between } from 'styled-breakpoints';
import ParticleEffectButton from 'react-particle-effect-button';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { Trans } from 'gatsby-plugin-react-i18next';

const Form = styled.form`
  width: 50%;
  display: flex;
  flex-direction: column;

  ${props => props.fullWidth && css`
    width: 100%;
  `};

  ${between('mini', 'mobile')} {
    width: 100%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`;

const StyledInput = styled(Input)`
  width: 100%;
  color: black;
  background-color: white;
  margin-bottom: 20px;
`;

const MessageTextarea = styled(Textarea)`
  width: 100%;
  color: black;
  background-color: white;
  height: 200px;

  textarea {
    height: 170px !important;
  }
`;

const ButtonWrapper = styled.div`
  margin: 20px 0;
  align-self: flex-end;
  button {
    width: 150px;
  }

  ${only('mini')} {
    div {
      width: 100%;
    }

    width: 100%;

    button {
      width: 100%;
    }
  }
`;

const ContactUsFrom = ({ fullWidth, button, autoFocus, buttonId }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [text, setText] = useState('');
  const [name, setName] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const { navigate } = useI18next();

  const navigateToSuccess = useCallback(() => {
    navigate('/contact/success');
  }, []);

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault();
    setSubmitting(true);
    try {
      await axios.post("https://getform.io/f/4391f357-a1e2-4bea-a154-123d0a5fd321", {
        email, phone, subject: text, name
      });
      setSubmitting(false);
      setSubmitted(true);
    } catch {
      setSubmitting(false);
    }
  }, [email, phone, text, name, navigateToSuccess]);

  const handleEmailChange = useCallback(({ target }) => {
    setEmail(target.value);
  }, []);

  const handleTextChange = useCallback(({ target }) => {
    setText(target.value);
  }, []);

  const handlePhoneChange = useCallback(({ target }) => {
    setPhone(target.value);
  }, []);

  const handleNameChange = useCallback(({ target }) => {
    setName(target.value);
  }, []);

  const buttonText = () => {
    if (submitting || submitted) return "Sending...";
    return "Send"
  }

  return (
    <Form fullWidth={fullWidth} onSubmit={handleSubmit}>
      <Wrapper>
        <StyledInput
          placeholder={t('Name')}
          value={name}
          onChange={handleNameChange}
          name="name"
          autoFocus={autoFocus}
        />
        <StyledInput
          placeholder={t('Required Phone')}
          value={phone}
          onChange={handlePhoneChange}
          type="tel"
          name="phone"
          required
        />
      </Wrapper>
      <StyledInput
        placeholder={t('Required Email')}
        value={email}
        onChange={handleEmailChange}
        type="email"
        name="email"
        required
      />

      <MessageTextarea
        value={text}
        onChange={handleTextChange}
        placeholder={t('Hello Appsforce Team')}
        name="subject"
      />
      <ButtonWrapper>
        <ParticleEffectButton
          color={button === 'info' ? '#fff' : '#000089'}
          hidden={submitted}
          duration={1000}
          onComplete={navigateToSuccess}
        >
          <Button
            variant="contained"
            color={button}
            size="large"
            sx={{ height: 50, fontSize: 19 }}
            type="submit"
            disabled={submitted || submitting}
            disabledColor={button || "primary"}
            id={buttonId}
          >
            <Trans>
              {buttonText()}
            </Trans>
          </Button>
        </ParticleEffectButton>
      </ButtonWrapper>
    </Form>
  )
}

export default ContactUsFrom;
