import React from "react";
import { LinkItem } from './Navbar.style';
import { Link, Text } from '../Common';

const NavbarLink = ({ link, text, className, onClick, variant = "h6", activeStyle, partiallyActive, children }) => (
  <LinkItem className={className} onClick={onClick}>
    <Link to={link} activeStyle={activeStyle} partiallyActive={partiallyActive}>
      <Text text={text} variant={variant} component="div">
        {children}
      </Text>
    </Link>
  </LinkItem>
)

export default NavbarLink;
