import { keyframes, css } from 'styled-components';

const flyAnimation = keyframes`
  0% {
    transform: translateX(-100vw);
  }
  100% {
    transform: translateX(220px);
  }
`;

export const fly = css`
  animation: ${flyAnimation} 10s cubic-bezier(0.25, 0.46, 0.45, 0.94) both
    infinite;
`;

const slideInLeftAnimation = keyframes`
  0% {
    transform: translateX(-200px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

export const slideInLeft = css`
  animation: ${slideInLeftAnimation} 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
`;

const textFocusInAnimation = keyframes`
  0% {
    filter: blur(2px);
    opacity: 0;
  }
  100% {
    filter: blur(0px);
    opacity: 1;
  }
`;

export const textFocusIn = css`
  animation: ${textFocusInAnimation} 1s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    both;
`;

const slideInBottomAnimation = keyframes`
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;
export const slideInBottom = css`
  animation: ${slideInBottomAnimation} 0.8s 0.5s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
`;

const bgAnimation = keyframes`
  0% {
    background: #eee;
  }
  100% {
    background: #fff;
  }
`;

export const bg = css`
  animation: ${bgAnimation} 2s linear infinite alternate both;
`;

const fadeInAnimation = keyframes`
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

export const fadeIn = css`
  animation: ${fadeInAnimation} 1s ease-out both;
`;

const talkingAnimation = keyframes`
   0%,
  100% {
    outline: 1px solid #9b00ffa8;
  }
  5%,
  20%,
  85% {
    outline: 8px solid #9b00ffa8;
  }
  15%,
  30%,
  55%,
  75%,
  90% {
    outline: 12px solid #9b00ffa8;
  }
  35%,
  45%,
  60%,
  70% {
    outline: 5px solid #9b00ffa8;
   }
  40%,
  67%,
  95% {
    outline: 12px solid #9b00ffa8;
  }
  100% {
    outline: 1px solid #9b00ffa8;
  }
`;

export const talking = css`
  animation: 5s infinite ${talkingAnimation};
`;

const floatAnimation = keyframes`
  0% {
    transform: translateY(0px) rotate(45deg)
  }

  40% {
    transform: translateY(5px) rotate(45deg)
  }

  50% {
    transform: translateY(5px) rotate(45deg)
  }

  100% {
    transform: translateY(0px) rotate(45deg)
  }
`;

export const float = css`
  animation: ${floatAnimation} 2s ease-in-out infinite;
`;

const fadeInUpAnimation = keyframes`
  0% {
    transform: translate3d(0, 4rem, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
`;

export const fadeInUp = css`
  animation: ${fadeInUpAnimation} 0.3s ease-out;
  animation-fill-mode: both;
`;

// Left To Right
export const scrollXLR = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - calc(clamp(10rem, 1rem + 40vmin, 30rem) / 14)));
  }
`;

// Right To Left
export const scrollXRL = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(100% - calc(clamp(10rem, 1rem + 40vmin, 30rem) / 14)));
  }
`;

export const scrollY = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(calc(-100% - var(--gap)));
  }
`;

export const fade = keyframes`
to {
  opacity: 0;
  visibility: hidden;
}
`;
