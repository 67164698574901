import React, { useEffect, useCallback } from "react";
import styled from "styled-components";
import AppsforceSoldier from "/src/assets/soldiers/appsforce_soldier.svg";
import NavbarLink from "./NavBarLink";
import { slideInLeft } from "src/style/animations";
import { URL } from "src/url";

const Root = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  top: 60px;
  left: 0;
  padding: 50px 20px 10px 20px;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LinksList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const MobileNavbarLink = styled(NavbarLink)`
  margin-bottom: 30px;
  ${slideInLeft};
  animation-delay: ${(props) => props.animationDelay};
`;

const MobileMenu = ({ onCloseMenu, language }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    const floatContactUsButton = document.querySelector("#float-contact-us-button");
    if (floatContactUsButton) floatContactUsButton.style.display = "none";

    return () => {
      document.body.style.overflow = "unset";
      if (floatContactUsButton) floatContactUsButton.style.display = "flex";
    };
  }, []);

  const handleContactUsClick = useCallback(() => {
    onCloseMenu();
    const contentUs = document.querySelector("#contentUs");
    const top = contentUs.getBoundingClientRect().top + window.pageYOffset - 15;
    if (typeof window !== "undefined") {
      window.scrollTo({ behavior: "smooth", top });
    }
  }, []);

  return (
    <Root>
      <LinksList>
        <MobileNavbarLink link={URL.HOME} text="Home" variant="h5" onClick={onCloseMenu} activeStyle />
        <MobileNavbarLink link={URL.SERVICES} text="Services" variant="h5" animationDelay=".1s" onClick={onCloseMenu} activeStyle />
        <MobileNavbarLink link={URL.CAREERS} text="Careers" variant="h5" animationDelay=".15s" onClick={onCloseMenu} activeStyle />
        {language === "he" && <MobileNavbarLink link={URL.BLOGS} text="Blog" variant="h5" animationDelay=".2s" onClick={onCloseMenu} activeStyle />}
        <MobileNavbarLink link={URL.ABOUT} text="About" variant='h5' animationDelay=".25s" onClick={onCloseMenu} activeStyle />
        <MobileNavbarLink link={URL.CONTACT} text="Contact" variant="h5" animationDelay=".3s" onClick={onCloseMenu} activeStyle />
      </LinksList>
      <AppsforceSoldier />
    </Root>
  );
};

export default MobileMenu;
