import React from "react";
import { useLocation } from "@reach/router"
import { graphql, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';
import { breadcrumbsSchema } from '../../../schema/breadcrumbs';

const SEO = ({ title, description, image, article, hideAlternateEN, breadcrumbsElementsSchema, organizationSchema, articelSchema }) => {
  const { site } = useStaticQuery(query);
  const { pathname, href } = useLocation();
  const { t } = useTranslation();
  const { siteUrl: originalSiteUrl, originalPath, language } = useI18next();
  const alternateEN = !hideAlternateEN && `${originalSiteUrl}${originalPath}`;
  const alternateHE = `${originalSiteUrl}/he${originalPath}`;

  const {
    defaultTitle,
    defaultDescription,
    siteUrl,
    defaultImage,
    twitterUsername,
  } = site.siteMetadata;

  const seo = {
    title: t(title) || defaultTitle,
    description: t(description) || defaultDescription,
    image: image || defaultImage,
    url: `${siteUrl}${pathname}`,
  };

  const breadcrumbsHomePage = {
    name: t("Home"),
    url: `https://appsforce.io/${language === 'he' ? 'he/' : ''}`
  }

  return (
    <Helmet title={seo.title}>
      <meta name="description" content={seo.description} />
      {seo.image && <meta name="image" property="og:image" content={seo.image} />}
      {seo.url && <meta property="og:url" content={seo.url} />}
      {(article ? true : null) && <meta property="og:type" content="article" />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      <meta name="twitter:card" content="summary_large_image" />
      {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
      {alternateEN && <link rel="alternate" href={alternateEN} hreflang="en" />}
      {alternateHE && <link rel="alternate" href={alternateHE} hreflang="he" />}
      {(alternateEN || alternateHE) && <link rel="alternate" href={alternateEN || alternateHE} hreflang="x-default" />}
      <link rel="canonical" href={href} />
      <script type="application/ld+json">{JSON.stringify(breadcrumbsSchema(breadcrumbsHomePage, breadcrumbsElementsSchema))}</script>
      {organizationSchema && <script type="application/ld+json">{JSON.stringify(organizationSchema)}</script>}
      {articelSchema && <script type="application/ld+json">{JSON.stringify(articelSchema)}</script>}
    </Helmet>
  );
}

export default SEO;

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl: url
      }
    }
  }
`
